<template>
  <div class="complaint">
    <div class="theme">
      {{ title }}
    </div>
    <load-list :interFace="interFace">
      <template v-slot:list="props">
        <div class="content" v-for="(item, index) in props.list" :key="index">
          <p class="title">{{ index + 1 }}.{{ item.question }}</p>
          <van-field
            class="text-area"
            maxlength="200"
            show-word-limit
            v-model="answerList[index].content"
            autosize
            rows="6"
            type="textarea"
            placeholder="请输入内容...（限200字）"
            contenteditable="true"
          />
        </div>
      </template>
    </load-list>
    <van-button class="btn" @click="submit">提交</van-button>
  </div>
</template>
<script>
import { Toast } from 'vant'
import LoadList from "@/components/SearchLoadList";
import { getCollectDetail, subCollect } from "../api";
export default {
  name: "complaint",
  components: {
    LoadList,
  },
  data() {
    return {
      interFace: {
          api: getCollectDetail,
          params: {
            id: this.$route.params.id,
          },
        },
      answerList: [],
      title: "",
    };
  },
  created() {
    let id = this.$route.params.id;
    this.init(id);
  },
  methods: {
    init(id) {
      let params = {
        id: id,
      };
      getCollectDetail(params).then((res) => {
        console.log("res", res);
        this.title = res.data.title;
        this.answerList = res.data.items.map((e) => {
          return {
            id: e.id,
            content: "",
          };
        })
      });
    },
    submit() {
      Toast.loading("提交中")
      let params = {
        id: this.$route.params.id,
        content: this.answerList,
        token: window.localStorage.getItem("token"),
      };
      subCollect(params).then((res) => {
        console.log("提交", res);
        if (res.code == 0) {
            Toast.success("提交成功");
            // this.getUserInfo();
            setTimeout(() => {
              this.$router.replace("/proposal");
            }, 2000);
          }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.complaint {
  padding: 0.27rem 0.4rem 0;
  padding-bottom: 1rem;
  position: relative;
  min-height: 14.5rem;
  background: url("../../../assets/images/organization/bg.png");
  background-size: 100% 100%;
  .theme {
    width: 5.5rem;
    margin: 0.3rem auto;
    color: #333333;
    font-size: 0.36rem;
    font-weight: 700;
    text-align: center;
    line-height: 0.6rem;
  }
  .popup {
    text-align: center;
    p {
      margin-top: 0.2rem;
      line-height: 0.2rem;
    }
  }
  .upPic {
    margin-top: 0.3rem;
    .upTitle {
      line-height: 0.8rem;
      font-size: 0.3rem;
      span {
        font-size: 0.24rem;
        color: #999999;
      }
    }
  }
  .title {
    margin-bottom: 0.2rem;
    color: #3b93ff;
  }
  .content {
    border-radius: 0.06rem;
    padding: 0.19rem 0.2rem;
    .text-area {
      border-radius: 0.2rem;
      background-color: #f6faff;
    }
    .operation {
      width: 40%;
      margin: 0.4rem auto;
      text-align: center;
      .play-icon {
        padding: 0.04rem;
        border-radius: 0.08rem;
        height: 0.8rem;
        width: 0.8rem;
        background: #00dc51;
        margin: 0 auto;
        p {
          height: 100%;
          // background: url("../assets/mai.png") no-repeat;
          background-size: 100% 100%;
        }
      }

      .y1 {
        margin-top: 0.1rem;
        font-size: 0.32rem;
      }
      .y2 {
        color: #999;
        margin-top: 0.03rem;
        font-size: 0.24rem;
      }
    }
    .operation:active .play-icon {
      background: rgba(0, 220, 81, 1);
    }
  }
  .btn {
    color: #fff;
    background: #3b93ff;
    width: 6.55rem;
    display: block;
    border-radius: 0.2rem;
    line-height: 0.88rem;
    margin: 1rem auto;
    font-size: 0.38rem;
    letter-spacing: 0.1rem;
  }
}
</style>